import React, {Component, Fragment} from "react";
import './App.css';
import {Link, withRouter} from "react-router-dom";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import Routes from "./Routes";
import {Auth} from "aws-amplify";

export class App extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            await Auth.currentSession();
            this.userHasAuthenticated(true);
        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }
        if (this._isMounted) {
            this.setState({isAuthenticating: false});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    userHasAuthenticated = authenticated => {
        if (this._isMounted) {
            this.setState({isAuthenticated: authenticated});
        }
    }

    handleLogout = async event => {
        await Auth.signOut();
        this.userHasAuthenticated(false);
        this.props.history.push("/login");
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated
        };

        return (
            !this.state.isAuthenticating &&
            <div className="App container">
                <Navbar fluid collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link to="/">Home</Link>
                        </Navbar.Brand>
                        <Navbar.Brand>
                            <Link to="/build">Build</Link>
                        </Navbar.Brand>
                        <Navbar.Brand>
                            <Link to="/deploy">Deploy</Link>
                        </Navbar.Brand>
                        <Navbar.Brand>
                            <Link to="/undeploy">Undeploy</Link>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            {this.state.isAuthenticated
                                ? <NavItem onClick={this.handleLogout}>Log out</NavItem>
                                : <Fragment>
                                    <LinkContainer to="/login">
                                        <NavItem>Log in</NavItem>
                                    </LinkContainer>
                                </Fragment>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Routes childProps={childProps}/>
            </div>
        );
    }
}

export default withRouter(App);

