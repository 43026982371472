import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from 'aws-amplify';
import  { Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AUTH_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        //sandbox
        userPoolId: process.env.REACT_APP_AUTH_USERPOOLID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        //sandbox
        userPoolWebClientId: process.env.REACT_APP_AUTH_USERPOOLWEBCLIENTID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: 'localhost',
        // // OPTIONAL - Cookie path
        //     path: '/',
        // // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        // // OPTIONAL - Cookie secure flag
        // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true
        // },

        // OPTIONAL - customized storage object
        //storage: new MyStorage(),

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
         endpoints: [
             {
                 name: "staccip",
                 endpoint: process.env.REACT_APP_API_ENDPOINT_STACCIP,
                 //sandbox
                 //endpoint: "https://dev-1-api.us.wombat.sandbox.digital.specsavers.co.uk",
                 custom_header: async () => {
                     return  { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                 }
             }
         ]
    }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
