import React from 'react';
import './deploy-result-list.style.css';
import './../../Common.css';
import BuildResult from './deploy-result.component'
import {Panel} from "react-bootstrap";

export const DeployResultList = props => {

    return (
        <Panel>
            <Panel.Heading>
                <Panel.Title componentClass="h3">Submitted jobs</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
                {props.results.map(result => <BuildResult key={result.submitId} result={result}/>)}
            </Panel.Body>
        </Panel>
    );
};
