import React from "react";
import './undeploy-result.style.css';
import './../../Common.css';
import {Button, Glyphicon} from "react-bootstrap";
import {API} from "aws-amplify";

export default class UndeployResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: props.result,
            status: 'PENDING',
            continueFetching: true,
            numberOfTries: 0
        }
        this.refreshPeriod = 5000;
        this.maxTriesCount = 20;
        this.checkBuildStatus = this.checkBuildStatus.bind(this);
    }

    componentDidMount() {
        setTimeout(this.checkBuildStatus, this.refreshPeriod);
    }

    async checkBuildStatus() {
        this.setState({
            numberOfTries: (this.state.numberOfTries + 1)
        });

        try {
            const res = await API.get('staccip', '/build/proxy', {queryStringParameters: {url: this.state.result.joburl}});
            console.log("response: " + res);
            this.setState({
                status: 'READY'
            });
        } catch (e) {
            console.log(e.response);
            if (this.state.numberOfTries < this.maxTriesCount) {
                setTimeout(this.checkBuildStatus, this.refreshPeriod);
            } else {
                this.setState({
                    status: 'TIME OUT'
                });
            }
        }
    }

    openInTab(){
        window.open(this.state.result.joburl, '_blank');
    }

    render() {
        return (
            <div className='buildResultContainer'>
                <div>
                    Start Date: <i>{this.state.result.startDate}</i>,<br/>
                    GIT ssh url: <i>{this.state.result.queryStringParameters.git_ssh_url}</i>,
                    Tag: <i>{this.state.result.queryStringParameters.tag}</i>,
                    Profile: <i>{this.state.result.queryStringParameters.profile}</i>,
                    Region: <i>{this.state.result.queryStringParameters.region}</i>,
                    Stage: <i>{this.state.result.queryStringParameters.stage}</i>
                </div>
                <div>
                    Status:
                    {this.state.status === 'PENDING' && <Glyphicon glyph="refresh" className="spinning"/>}
                    &nbsp;<span
                    className={`status-label-${this.state.status.replace(/\s/g, "")}`}>{this.state.status}</span>
                    &nbsp;
                    <Button onClick={this.openInTab.bind(this)} disabled={this.state.status==='PENDING' || this.state.status==='TIME OUT'}>Open Staccip job view in new tab</Button>
                </div>
            </div>
        )
    }
};
