import React, {Component} from "react";
import "../Common.css";
import "./Deploy.css";
import {API} from 'aws-amplify';
import DeployForm from "./deploy.components/deploy-form.component"
import Collapsible from "./../../components/collapsible.component"
import {DeployResultList} from "./deploy.components/deploy-result-list.component";

export default class Deploy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitCounter: 0,
            results: [],
            git_ssh_url: '',
            profile: '',
            tag: '',
            stage: '',
            region: ''
        };
    }

    submitDeploy(submitId, callback) {
        let apiName = 'staccip';
        let path = '/deploy/trigger';
        let myInit = {
            headers: {
                'Content-Type': 'application/json'
            },
            queryStringParameters: {
                git_ssh_url: this.state.git_ssh_url,
                profile: this.state.profile,
                tag: this.state.tag,
                stage: this.state.stage,
                region: this.state.region
            }
        };

        API.post(apiName, path, myInit).then(response => {
            response.job.submitId = submitId;
            this.setState(prevState => ({
                results: [response.job].concat(prevState.results)
            }));
            callback(null);
        }).catch(error => {
            this.setState({submitCounter: submitId - 1});
            console.log('post error');
            callback(error.response.data.message);
        });
    }

    handleSubmit = (git_ssh_url,
                    profile,
                    tag,
                    stage,
                    region, callback) => {
        console.log(git_ssh_url, profile, tag, stage, region);
        const submitCounterNewValue = this.state.submitCounter + 1;
        this.setState({
            git_ssh_url: git_ssh_url,
            profile: profile,
            tag: tag,
            stage: stage,
            region: region,
            submitCounter: submitCounterNewValue
        }, () => {
            this.submitDeploy(submitCounterNewValue, callback);
        });
    };

    render() {
        return (
            <div className="Deploy">
                <DeployForm handleSubmit={this.handleSubmit}/>
                {this.state.results.length > 0 ?
                    <DeployResultList results={this.state.results}/>
                    : ''
                }
                {this.state.results.length > 0 ?
                    <Collapsible title="DEBUG">
                        <p>Last response:<br/>
                            {JSON.stringify(this.state.results[0])}</p>
                    </Collapsible>
                    : ''
                }
            </div>
        );
    }
}







