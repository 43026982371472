import React from "react";
import { FormGroup, FormControl, ControlLabel} from "react-bootstrap";

const RegionDropdown = (props) => (
    <FormGroup controlId="region">
        <ControlLabel>Region</ControlLabel>
        <FormControl
            autoFocus
            componentClass="select"
            value={props.value}
            onChange={props.onChange}
            >
            <option value="eu-west-1">Europe (Ireland) eu-west-1</option>
            <option value="us-east-1">US East (N. Virginia) us-east-1</option>
            <option value="ap-southeast-2">Asia Pacific (Sydney) ap-southeast-2</option>
        </FormControl>
    </FormGroup>
);

export default RegionDropdown;
