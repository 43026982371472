import React from "react";
import { FormGroup, FormControl, ControlLabel} from "react-bootstrap";

const ProfileDropdown = (props) => (
    <FormGroup controlId="profile">
        <ControlLabel>Profile</ControlLabel>
        <FormControl
            autoFocus
            componentClass="select"
            value={props.value}
            onChange={props.onChange}
            >
            <option value="sandbox">sandbox</option>
            <option value="devops">devops</option>
            <option value="non-prod">non-prod</option>
            <option value="prod">prod</option>
        </FormControl>
    </FormGroup>
);

export default ProfileDropdown;
