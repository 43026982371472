import React from "react";
import './deploy-form.style.css';
import './../../Common.css';
import LoaderButton from "../../../components/LoaderButton.js";
import ProfileDropdown from "../../../components/ProfileDropdown.js";
import RegionDropdown from "../../../components/RegionDropdown";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";

export default class DeployForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            git_ssh_url: '',
            profile: 'non-prod',
            tag: '',
            stage: '',
            region: 'eu-west-1',
            isLoading: false,
            error : ""
        };

    }

    handleSubmit = (e) => {
        this.setState({isLoading: true});
        e.preventDefault();

        this.props.handleSubmit(this.state.git_ssh_url, this.state.profile, this.state.tag, this.state.stage, this.state.region,(err) => {
            if (err) {
                console.log(err);
                this.setState({isLoading: false, error: err});
            } else{
                this.setState({isLoading: false});
           }
        }
        );
        console.log('submit handled');
    };

    handleInputChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };


    render() {
        return (<div className='formOuter'>
                <div align="center">
                    <h1>Deploy</h1>
                </div>
                <div align="center">
                    <p  className="status-label-ERROR">{this.state.error}</p>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="git_ssh_url">
                        <ControlLabel>Git ssh url</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.git_ssh_url}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="tag">
                        <ControlLabel>Tag</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.tag}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <ProfileDropdown onChange={this.handleInputChange} value={this.state.profile}/>
                    <RegionDropdown onChange={this.handleInputChange} value={this.state.region}/>
                    <FormGroup controlId="stage">
                        <ControlLabel>Stage</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.stage}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={false}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Submit"
                        loadingText="Submitting…"
                    />
                </form>
            </div>
        );
    }
};
