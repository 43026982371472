import React from "react";
import {Route, Switch} from "react-router-dom";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import Home from "./containers/Home";
import Build from "./containers/build.container/Build";
import Deploy from "./containers/deploy.container/Deploy";
import Undeploy from "./containers/undeploy.container/Undeploy";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

export default ({childProps}) =>
    <Switch>
        <AuthenticatedRoute path="/" exact component={Home} props={childProps}/>
        <AppliedRoute path="/login" exact component={Login} props={childProps}/>
        <AuthenticatedRoute path="/build" exact component={Build} props={childProps}/>
        <AuthenticatedRoute path="/deploy" exact component={Deploy} props={childProps}/>
        <AuthenticatedRoute path="/undeploy" exact component={Undeploy} props={childProps}/>
        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound}/>
    </Switch>;
