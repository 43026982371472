import React from "react";
import './build-form.style.css';
import './../../Common.css';
import LoaderButton from "../../../components/LoaderButton.js";
import { FormGroup, FormControl, ControlLabel, Checkbox } from "react-bootstrap";

export default class BuildForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            branch: '',
            ci_release: false,
            git_ssh_url: '',
            isLoading: false,
            error : ""
        };

    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleCheckboxChange = (event) => {
        this.setState({
            ci_release: event.target.checked
        });
    };

    handleSubmit = (e) => {
        this.setState({isLoading:true});
        e.preventDefault();
        this.props.handleSubmit(this.state.branch, this.state.ci_release, this.state.git_ssh_url,(err) => {
            if (err) {
                console.log(err);
                this.setState({isLoading: false, error: err});
            } else{
                this.setState({isLoading: false});
            }
        });
    };

    render() {
        return (<div className='formOuter'>
                <div align="center">
                    <h1>Build</h1>
                </div>
                <div align="center">
                    <p  className="status-label-ERROR">{this.state.error}</p>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="git_ssh_url">
                        <ControlLabel>Git ssh url</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.git_ssh_url}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="branch">
                        <ControlLabel>Branch</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={this.state.branch}
                            onChange={this.handleInputChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="ci_release">
                        <ControlLabel>Create release</ControlLabel>
                        <Checkbox
                            checked={this.state.ci_release}
                            onChange={this.handleCheckboxChange}
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={false}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Submit"
                        loadingText="Submitting…"
                    />
                </form>
            </div>
        );
    }
};
