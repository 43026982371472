import React, {Component} from "react";
import "../Common.css";
import "./Build.css";
import {API} from 'aws-amplify';
import BuildForm from "./build.components/build-form.component"
import {BuildResultList} from "./build.components/build-result-list.component"
import Collapsible from "./../../components/collapsible.component"

export default class Build extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitCounter: 0,
            results: [],
            branch: '',
            ci_release: false,
            git_ssh_url: ''
        };
    }

    submitBuild(submitId, callback) {
        let apiName = 'staccip';
        let path = '/build/cognito';
        let myInit = {
            headers: {
                'Content-Type': 'application/json'
            },
            queryStringParameters: {
                git_ssh_url: this.state.git_ssh_url,
                branch: this.state.branch,
                ci_release: this.state.ci_release
            }
        };

        API.post(apiName, path, myInit).then(response => {
            response.job.submitId = submitId;
            this.setState(prevState => ({
                results: [response.job].concat(prevState.results)
            }));
            callback();
        }).catch(error => {
            console.log(error.response);
            callback(error.response.data.message);
        });
    }

    handleSubmit = (submittedBranch, submittedCiRelease, gitSshUrl, callback) => {
        console.log(submittedBranch, submittedCiRelease, gitSshUrl);
        const submitCounterNewValue = this.state.submitCounter + 1;
        this.setState({
            branch: submittedBranch,
            ci_release: submittedCiRelease,
            git_ssh_url: gitSshUrl,
            submitCounter: submitCounterNewValue
        }, () => {
            this.submitBuild(submitCounterNewValue, callback);
        });
    }

    render() {
        return (
            <div className="Build">
                <BuildForm handleSubmit={this.handleSubmit}/>

                {this.state.results.length > 0 ?
                    <BuildResultList results={this.state.results}/>
                    : ''
                }
                {this.state.results.length > 0 ?
                    <Collapsible title="DEBUG">
                        <p>{JSON.stringify(this.state.results[0])}</p>
                    </Collapsible>
                    : ''
                }
            </div>
        );
    }
}







