import React from 'react';
import './build-result-list.style.css';
import './../../Common.css';
import BuildResult from './build-result.component';
import {Panel} from "react-bootstrap";


export const BuildResultList = props => {

    return (
        <Panel>
            <Panel.Heading>
                <Panel.Title componentClass="h3">Submitted jobs</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
                {props.results.map(result => <BuildResult key={result.submitId} result={result}/>)}
            </Panel.Body>
        </Panel>
        );
};
