import React, { Component } from "react";
import "./Common.css";
import logo from "../images/jenkins.png";


export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
          <img alt="logo" src={logo}/>
          <h1>Welcome to Staccip</h1>
        </div>
      </div>
    );
  }
}
