import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Login.css";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton.js";
import logo from "../images/jenkins.png";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      newPasswordChallenge: false,
      newPassword: "",
      newPasswordConfirm:"",
      user: null,
      error: " "
    };
  }

  validateForm() {
    const valid =  this.state.email.length > 0 && this.state.password.length > 0;
    return valid;
  }

  validateNewPasswordForm() {
    const valid =  this.state.newPassword.length > 0 && this.state.newPassword === this.state.newPasswordConfirm;
    this.setState({error: "Password missing or not matching",  isLoading: false});
    return valid;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({ newPasswordChallenge: true, isLoading: false, user: user, error: "Please change password" });
      }else{
        this.props.userHasAuthenticated(true);
        this.props.history.push(this.props.location.search.split("=").reverse()[0]);
      }
    } catch (e) {
      this.setState({ isLoading: false, error: e.message });
    }
  };

  handleNewPasswordSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    if(!this.validateNewPasswordForm()){
      return;
    }

    try {
      await Auth.completeNewPassword(
            this.state.user,
            this.state.newPassword
      );
      this.props.userHasAuthenticated(false);
      this.props.history.push("/login");
      this.setState({ newPasswordChallenge: false, isLoading: false, error: " " });
    } catch (e) {
      this.setState({ isLoading: false, error: e.message });
    }
  };

  render() {
    if(this.state.newPasswordChallenge){
      return (
          <div className="Login">
            <div align="center">
              <img src={logo} alt="logo"/>
            </div>
            <div align="center">
              <p  className="status-label-ERROR">{this.state.error}</p>
            </div>
            <form onSubmit={this.handleNewPasswordSubmit}>
              <FormGroup controlId="newPassword">
                <ControlLabel>New Password</ControlLabel>
                <FormControl
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                    type="password"
                />
              </FormGroup>
              <FormGroup controlId="newPasswordConfirm" >
                <ControlLabel>Confirm Password</ControlLabel>
                <FormControl
                    value={this.state.newPasswordConfirm}
                    onChange={this.handleChange}
                    type="password"
                />
              </FormGroup>
              <LoaderButton
                  block
                  bsSize="large"
                  disabled={false}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Submit"
                  loadingText="Submitting…"
              />
            </form>
          </div>
      );
    }else{
      return (
          <div className="Login">
            <div align="center">
              <img src={logo} alt="logo"/>
            </div>
            <div align="center">
              <p className="status-label-ERROR">{this.state.error}</p>
            </div>
            <form onSubmit={this.handleSubmit}>
              <FormGroup controlId="email" >
                <ControlLabel>Email</ControlLabel>
                <FormControl
                    autoFocus
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    autoComplete="username"
                />
              </FormGroup>
              <FormGroup controlId="password" >
                <ControlLabel>Password</ControlLabel>
                <FormControl
                    value={this.state.password}
                    onChange={this.handleChange}
                    type="password"
                    autoComplete="current-password"
                />
              </FormGroup>
              <LoaderButton
                  block
                  bsSize="large"
                  disabled={!this.validateForm()}
                  type="submit"
                  isLoading={this.state.isLoading}
                  text="Log in"
                  loadingText="Logging in…"
              />
            </form>
          </div>
      );
    }
  }
}
